import React from "react";
import Head from "next/head";
import Layout from "layout/Layout/index.js";
import Image from "next/image";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import Link from "next/link";

function pageNotFound() {
  const { t } = useTranslation(["common", "nav"]);

  return (
    <Layout>
      <Head>
        <title>{t('404title')}</title>
        <meta name="description" content={t('404title')} />

      </Head>
      <div className="w-full bg-white">
        <div className="container pt-20 pb-10 mx-auto">
          <div className="flex flex-col justify-center items-center">
            <Image src="/404image.png" alt="404image"  title="404image"  width="417" height="379" />
            <h1 className="my-10 text-5xl font-semibold text-titleColor">404 Sayfa Bulunamadı</h1>
            <p className="text-titleColor">Üzgünüz, aradağın sayfayı bulamadık.</p>
            <p className="text-titleColor">Belki aşağıdaki seçenekler sana yardımcı olabilir.</p>

            <div className="flex mt-8">
              <a href="/">
                <div className="px-3 py-2 mr-4 rounded-md border-2 border-makdosSecondaryGray">
                  Anasayfa
                </div>
              </a>
              <Link href="/domainsearch" as={t("nav:domainfind")}>
                <a >
                  <div className="px-3 py-2 mr-4 rounded-md border-2 border-makdosSecondaryGray">
                    Domain
                  </div>
                </a>
              </Link>
              <Link href="/web-hosting/cpanel" as={t("nav:personalwebhosting")}>
                <a>
                  <div className="px-3 py-2 mr-4 rounded-md border-2 border-makdosSecondaryGray">
                    Hosting
                  </div>
                </a>
              </Link>
              <Link href="/servers/cloud/windows" as={t("nav:windowsvirtualservers")}>
                <a >
                  <div className="px-3 py-2 rounded-md border-2 border-makdosSecondaryGray">
                    Sunucular
                  </div>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default pageNotFound;

export const getStaticProps = async ({ locale }) => {
  return {
    props: {
      ...await serverSideTranslations(locale, ["common", "nav"])
    }
  };
};